import axios from "axios";
import moment from 'moment';

class Sales {
  constructor() {
      this.Sales = axios.create({
      baseURL: process.env.REACT_APP_PUBLIC_DOMAIN_3,
      withCredentials: true,
    });
  }

  
  toISODate = (t) =>{
    return ('0'+ t.getDate()).slice(-2) + '-' +
           ('0'+ (t.getMonth()+1)).slice(-2) + '-' +
           t.getFullYear(t)
  }
    
  onlyread = () => {
    console.log('Read Sales from CMZ4')
    return this.Sales
    .get("sales")
    .then(({data}) => data.rows) // cara diario
    // .then(({data}) => console.log(data.rows)) // carga DDBB
    // .then(({data}) => console.log(typeof(data.rows)))
  }

  //Lectura de un año
  onlyreadAll = (event) => {
    const e = moment(event).format('YYYY-MM-DD 00:00:00:000')
    console.log('Reading last year ' + e)
    return this.Sales
    .get(`sales/all/${e}`)
    .then(({data}) => data.rows[1]) // carga DDBB
    // .then(({data}) =>console.log(data.rows[1])) // carga DDBB
    // .then(({data}) => data.rows) // cara diario
    // .then(({data}) => console.log(typeof(data.rows[1])))
  }

  onlyreadold = (olDate) => {
    const year = moment(olDate).get('year')
    const day = moment(olDate).get('date')
    let e = '';
    /*********** AÑO BISIESTO ************/
    
    if (moment(olDate).format('YYYY-MM-DD') >= moment('2024-03-01').format('YYYY-MM-DD') && moment(olDate).format('YYYY-MM-DD') <= moment('2025-02-28').format('YYYY-MM-DD') ) {
      e = moment(olDate).set('year', year - 1).set('date', day + 2).format('YYYY-MM-DD')
      }else {
      e = moment(olDate).set('year', year - 1).set('date', day + 1).format('YYYY-MM-DD')
      }   
      // const e =  moment(olDate).set('year', year - 1).set('date', day + 1).format('YYYY-MM-DD')
    
    /************************************/

    console.log('Reading old sales from mongo, date: ' + e)
    return this.Sales
    .get(`sales/old/${e}`)
    .then(({data}) => data)
    // .then(({data}) => console.log(data))
  }

  onlyreadoldcal = (olDate) => {
    // const e = moment('2022-12-13').format('YYYY-MM-DD')
    const e =  moment(olDate).format('YYYY-MM-DD')
    console.log('Reading old sales from mongo, date: ' + e)
    return this.Sales
      .get(`sales/old/${e}`)
      .then(({data}) => data)
  }

  create = (event) => {
    console.log(`Create Sales on mongo`)
    return this.Sales
      .post("sales/create", {event})
      .then(({ data }) => data);
  }

  read = (event) => {
    const e = moment(event).format('YYYY-MM-DD')
    console.log('Reading sales from mongo, date: ' + e)
    return this.Sales
      .get(`sales/data/${e}`)
      .then(({ data }) => data);
      // .then(({data}) => console.log(data.rows))
      
  }

  email = (event) => {
    console.log(`Sending email`)
    return this.Sales
      .post("sales/send", {event})
      .then(({ data }) => data);
  }
}

const salesService = new Sales();

export default salesService;