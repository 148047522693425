import React, { Component } from 'react';
import LoadingDots  from "../components/LoadingDots";
import sales from '../lib/sales-service';
import { Button, Form } from 'react-bootstrap';
import { withAuth } from "../lib/AuthProvider";
import Cal from "../components/date";
import CalNew from "../components/dateNew";
import CalOld from "../components/dateOld";
import moment from 'moment';
import $ from 'jquery';
import { GrUpdate } from "react-icons/gr";
import { IconContext } from "react-icons";
import sftp from '../lib/sftp-service';
import sap from '../lib/sap-service';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Select from 'react-select';

class Dev extends Component {
    state = {
        data: {
            data: {
                id:'',
                name: '',
                start: '',
            },
        },
        kpi: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        // kpiold: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        
        kpiold: [{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
        
        kpiold0: [{CODIGO:'',DESCRIPCION:'',FECHA: 'sin datos',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        history: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        id: [{_id: 0},{_id: 1},{_id: 2},{_id: 3},{_id: 4},{_id: 5},{_id: 6},{_id: 7},{_id: 8},{_id: 9},{_id: 10},{_id: 11}],
        hour: '',
        hora: '00',
        min: '00',
        isLoading: true,
        status: "loading",
        timer: '',
        autoplay: false,
        realtime: '',
        calendarDate: true,
        ppto: [{CENTRO: '',COSTE: '', DAY: '', FECHA:'', MARGEN: '', MONTH: '', VENTAS:'', YEAR:''},],
        sap: [{GJAHR: '', KOSTL_ORIGEN: '', LIFNR: '', KOSTL_DESTINO: '', PROYECTO: '', NAME1: '', STCD1: '', KTEXT_OR: '', KTEXT_DE: '', ENERO_PRESUP: '', ENERO_COMP: '', ENERO_PROVI: '', ENERO_REAL: '', FEBRERO_PRESUP: '', FEBRERO_COMP: '', FEBRERO_PROVI: '', FEBRERO_REAL: '', MARZO_PRESUP: '', MARZO_COMP: '', MARZO_PROVI: '', MARZO_REAL: '', ABRIL_PRESUP: '', ABRIL_COMP: '', ABRIL_PROVI: '', ABRIL_REAL: '', MAYO_PRESUP: '', MAYO_COMP: '', MAYO_PROVI: '', MAYO_REAL: '', JUNIO_PRESUP: '', JUNIO_COMP: '', JUNIO_PROVI: '', JUNIO_REAL: '', JULIO_PRESUP: '', JULIO_COMP: '', JULIO_PROVI: '', JULIO_REAL: '', AGOSTO_PRESUP: '', AGOSTO_COMP: '', AGOSTO_PROVI: '', AGOSTO_REAL: '', SEPTIEMBRE_PRESUP: '', SEPTIEMBRE_COMP: '', SEPTIEMBRE_PROVI: '', SEPTIEMBRE_REAL: '', OCTUBRE_PRESUP: '', OCTUBRE_COMP: '', OCTUBRE_PROVI: '', OCTUBRE_REAL: '', NOVIEMBRE_PRESUP: '', NOVIEMBRE_COMP: '', NOVIEMBRE_PROVI: '', NOVIEMBRE_REAL: '', DICIEMBRE_PRESUP: '', DICIEMBRE_COMP: '', DICIEMBRE_PROVI: '', DICIEMBRE_REAL: '', WAERS: '', TOTAL_PRESUPUESTO: '', TOTAL_COMPROMETIDO: '', TOTAL_PROVISIONADO: '', TOTAL_REAL: ''}],
        selectedOption: '',
    }

    componentDidMount() {
        setTimeout(() => {
            let hora = moment().format('HH')
            let min = moment().format('mm')
            this.handledbComplete()
            // this.handleSapComplete()

        }, 1000)
       
        this.setState({
            timer: setInterval(() => {
                let min = moment().format('mm:ss')
                switch (min) {
                    case '00:20' :
                    console.log('00!');
                        this.handleSapComplete()
                        break;
                    case '10:20':
                    console.log('10!');
                    this.handleSapComplete()
                        break;
                    case '20:20':
                    console.log('20!');
                     this.handleSapComplete()
                        break;
                    case '30:20':
                    console.log('30!');
                    this.handleSapComplete()
                        break;
                    case '40:20':
                    console.log('40!');
                    this.handleSapComplete()
                        break;
                    case '50:20':
                    console.log('50!');
                    this.handleSapComplete()
                        break;
                    default:
                    
                }
            }, 1000)
        })
    }
      
    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    convertArray = (kpi) => {
        kpi.sort((a,b) => {
            if (a.TIPO < b.TIPO){
                return -1;
            } return 1;
        })
        
        this.arraymove(kpi,0,77) //COMP
        this.arraymove(kpi,78,91) //E-COMMERCE
        this.arraymove(kpi,80,90) //I100
        this.arraymove(kpi,80,90) //I101
        this.arraymove(kpi,78,90) //I000
        this.arraymove(kpi,78,90) //I010
        this.arraymove(kpi,78,83) //NO COMP

        // this.arraymove(kpi,0,75) //COMP
        // this.arraymove(kpi,76,90) //E-COMMERCE
        // this.arraymove(kpi,78,89) //I100
        // this.arraymove(kpi,78,89) //I101
        // this.arraymove(kpi,76,89) //I000
        // this.arraymove(kpi,76,89) //I010
        // this.arraymove(kpi,76,82) //NO COMP

        
        // let ind = kpi.map((e,i) => e.CODIGO === 'T210' ?  i: '').filter(String)
        
        // this.arraymove(kpi,ind,62) //T210
        // this.arraymove(kpi,78,79) //NO COMP
    }
   
    arraymove = (arr, fromIndex, toIndex) => {
        let element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    filterShop = (kpi) => { //ELIMINAR TIENDAS
     
        let indexes = kpi.map((e,i) =>  e.CODIGO === 'T005' 
                                || e.CODIGO === 'T027'
                                || e.CODIGO === 'I302'
                                || e.CODIGO === 'I001'
                                || e.CODIGO === 'T035'
                                || e.CODIGO === 'T041'
                                || e.CODIGO === 'I032'
                                || e.CODIGO === 'T999'
                                || e.CODIGO === 'F006'
                                || e.CODIGO === 'F004'
                                || e.CODIGO === 'F001' 
                                || e.CODIGO === 'T609' 
                                || e.CODIGO === 'T301'  ?  i: '').filter(String)
                            
    for(let i = indexes.length -1; i >= 0; i--){
        kpi.splice(indexes[i],1)
    }

    let indexes2  = kpi.map((e,i) =>  e.CODIGO === 'T409'
                                || e.CODIGO === 'T029'
                                || e.CODIGO === 'D001'
                                || e.CODIGO === 'D101'
                                || e.CODIGO === null ? i: '').filter(String)

    for(let i = indexes2.length -1; i >= 0; i--){
        kpi.splice(indexes2[i],1)
        }
      
    }

    unionEco = (kpi) => { //UNIR ECORGANIC
        let indexes = kpi.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
        let date = moment(kpi[indexes[0]].FECHA).format('YYYY-MM-DD')
        // let date = kpi[0].FECHA
        console.log(date)
    if (date > '2022-10-03') {
        console.log('mayor '+ kpi.length)
        // let i = kpi.map((e,i) => e.CODIGO === 'T401' ?  i: '').filter(String)
        // kpi[i].CODIGO = 'T601'
        // console.log(kpi[i])
        let oldIndexes = kpi.map((e,i) => e.CODIGO === 'T401' || e.CODIGO === 'T402' || e.CODIGO === 'T403' || e.CODIGO === 'T404' || e.CODIGO === 'T405'|| e.CODIGO === 'T406' || e.CODIGO === 'T407' || e.CODIGO === 'T408' || e.CODIGO === 'T409'|| e.CODIGO === 'T410' || e.CODIGO === 'T411' || e.CODIGO === 'T412'?  i: '').filter(String)
        for(let i = oldIndexes.length -1; i >= 0; i--){
            kpi.splice(oldIndexes[i],1)
        }
       } else {
        console.log('menor ' + kpi.length)

        let oldIndexes = kpi.map((e,i) => e.CODIGO === 'T601' || e.CODIGO === 'T602' || e.CODIGO === 'T603' || e.CODIGO === 'T604' || e.CODIGO === 'T605' || e.CODIGO === 'T606' ?  i: '').filter(String)
        for(let i = oldIndexes.length -1; i >= 0; i--){
            kpi.splice(oldIndexes[i],1)
        }
        let i = kpi.map((e,i) => e.CODIGO === 'T401' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T601'
         i = kpi.map((e,i) => e.CODIGO === 'T402' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T602'
         i = kpi.map((e,i) => e.CODIGO === 'T403' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T603'
         i = kpi.map((e,i) => e.CODIGO === 'T404' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T604'
         i = kpi.map((e,i) => e.CODIGO === 'T405' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T605'
         i = kpi.map((e,i) => e.CODIGO === 'T406' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T606'

        let oldIndexes2 = kpi.map((e,i) =>  e.CODIGO === 'T607' || e.CODIGO === 'T210'|| e.CODIGO === 'T609'|| e.CODIGO === 'T610' || e.CODIGO === 'T611' || e.CODIGO === 'T612' ?  i: '').filter(String)
        for(let o = oldIndexes2.length -1; o >= 0; o--){
            kpi.splice(oldIndexes2[o],1)
        }
        
        
        let o = kpi.map((e,i) => e.CODIGO === 'T407' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T607'

         o = kpi.map((e,i) => e.CODIGO === 'T408' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T210'

        // o = kpi.map((e,i) => e.CODIGO === 'T409' ?  i: '').filter(String)
        // kpi[o].CODIGO = 'T609'

        o = kpi.map((e,i) => e.CODIGO === 'T410' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T610'

        o = kpi.map((e,i) => e.CODIGO === 'T411' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T611'

        o = kpi.map((e,i) => e.CODIGO === 'T412' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T612'

        }

        

    }

    handleSalesYear = () => {
        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }
        this.setState({
            isLoading: false,
        })
        let date = new Date("2024,11"); //Fecha inicial
        for (let i = 1; i < 90; i++){ //Suma de días a grabar
            let dateplus = date.addDays(i)
            sales.onlyreadAll(dateplus)
            .then((history) => {
                this.filterShop(history)
                this.unionEco(history)
                this.setState({
                    history,
                    isLoading: true,
                    autoplay: true,
                })
            })
            .then(()=>{// guardar fecha en cada línea
                let indexes = this.state.history.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
                const FECHA =  moment(this.state.history[indexes[0]].FECHA).format('YYYY-MM-DD')
                const {history} = this.state;
                for (let i = 0; i < history.length; i++) {
                    history[i] = {
                        ...history[i],
                        FECHA: FECHA
                    }
                };
                this.setState({
                    history,
                    isLoading: true,
                    autoplay: true,
                })
            })
            // .then(()=>{// guardar fecha en cada línea
            //     let indexes = this.state.history.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
            //     const FECHA =  moment(this.state.history[indexes[0]].FECHA).format('YYYY-MM-DD')
            //     const {history} = this.state;
            //     for (let i = 0; i < history.length; i++) {
            //         history[i] = {
            //             ...history[i],
            //             FECHA: FECHA
            //         }
            //     };
            //     this.setState({
            //         history,
            //         isLoading: true,
            //         autoplay: true,
            //     })
            // })
            .then(()=>{//guardar historico en mongo
                const history = this.state.history
                sales.create(history)
                   this.setState({
                       isLoading: true,
                       autoplay: true,
                   })
                })
            .catch(error => {
                this.setState({
                    status: "error",
                    isLoading: false
                });
            });
        }
    }
    
    handleSales = () => {
        this.setState({
            isLoading: false,
        });
        sales.onlyread ()
        .then((kpi) => {
            //Change Array order and save state
            this.filterShop(kpi)
            this.unionEco(kpi)
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then(() => {
            //convert Date and add to kpi.FECHA
            let indexes = this.state.kpi.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
            const FECHA =  moment(this.state.kpi[indexes[0]].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then(() => {
            const kpi = this.state.kpi
            sales.create (kpi)
            const dateHour = new Date()
            const hour = dateHour.getHours()
            this.setState({
                kpi,
                hour,
                status: "loaded",
                isLoading: true,
                autoplay: true,
            })
            console.log("estoy en sales complete")
            this.handleOldSales(kpi[0].FECHA)
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
                });
            });
    }

    handleOldSales = (olDate) => {
        this.setState({
            isLoading: false,
        });
        // let olDate = this.state.kpi[0].FECHA
        sales.onlyreadold(olDate)
        .then((kpiold) => {
            console.log("kpiold nuevo:", kpiold)
            if (kpiold.length > 1) {
                this.setState({
                    kpiold,
                    status: "loaded",
                    isLoading: true,
                })
            } else {
                this.setState({
                    kpiold: this.state.kpiold0,
                    status: "loaded",
                    isLoading: true,
                })
            }
        })
        .then (() => {
            if (this.state.kpiold[0].FECHA !== "sin datos" ) {
                //convert Date and add to kpi.FECHA
                let indexes = this.state.kpiold.map((e,i) => e.FECHA !== null ?  i: '').filter(String)
                const FECHA =  moment(this.state.kpiold[indexes[0]].FECHA).format('YYYY-MM-DD')
                const {kpiold} = this.state;
                for (let i = 0; i < kpiold.length; i++) {
                    kpiold[i] = {
                        ...kpiold[i],
                        FECHA: FECHA
                        }
                }
                this.setState({
                    kpiold,
                    isLoading: true,
                });
            }
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false,
            });
        });
    }

    handledbComplete = () => {
        const date =  moment().format('YYYY')
        console.log(date)
        ////Clean calendar
        {this.state.calendarDate === true ?
            this.setState({
                calendarDate: false
                })
            :
            this.setState({
                calendarDate: true
                })
        }
        ////////////////
        this.setState({
            isLoading: false
            })
        sap.read (date) 
        .then((sap) => {
            console.log(sap)
            this.setState({
                sap,
                isLoading: true,
                autoplay: true,
            })
        })
        .then (() => {
                    //convert Date and add to kpi.FECHA
                    let indexes = this.state.kpi.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
                    const FECHA =  moment().format('YYYY-MM-DD')
                    const {kpi} = this.state;
                    for (let i = 0; i < kpi.length; i++) {
                        kpi[i] = {
                            ...kpi[i],
                            FECHA: FECHA
                            }
                    };
                    console.log({kpi})
                    this.setState({
                        kpi,
                        isLoading: true,
                        autoplay: true,
                    });
                })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })
        .then(()=> {
            const {kpi} = this.state
            let hora = moment(kpi[0].updated_at).format("HH")
            let min =  moment(kpi[0].updated_at).format("mm")
            this.setState({
                hora,
                min
            })
            this.updateAlert()
        })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })
    }
    
    handledb = () => {
        this.setState({
            isLoading: false
        });
        sales.read ()
        .then((kpi) => {
            this.setState({
                kpi,
                status: "loaded",
                isLoading: true,
            });
        })
        .then (() => {
            //convert Date and add to kpi.FECHA
            const FECHA =  moment(this.state.kpi[0].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            });
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
            });
        });
    };

    handlesftpComplete = () => {
        const date =  moment().format('YYYY-MM-DD')
        this.setState({
            isLoading: false
            })
        sftp.readmongo (date) 
        .then((ppto) => {
            this.setState({
                ppto,
                isLoading: true,
                autoplay: true,
            })
        })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })
    }
   
    handleSapComplete = () => {
        const date =  moment().format('YYYY')
        console.log(date)
        this.setState({
            isLoading: false
            })
        sap.read (date) 
        .then((sap) => {
            console.log(sap)
            this.setState({
                sap,
                isLoading: true,
                autoplay: true,
            })
        })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })
    }

    tryParseDateFromString = (dateStringCandidateValue, format = "ymd") => {
      
        if (dateStringCandidateValue === ''){
        } else {
            if (!dateStringCandidateValue) { return null; }
            let mapFormat = format
                    .split("")
                    .reduce(function (a, b, i) { a[b] = i; return a;}, {});
            const dateStr2Array = dateStringCandidateValue.split(/[ :\-\/]/g);
            const datePart = dateStr2Array.slice(0, 3);
            let datePartFormatted = [
                    +datePart[mapFormat.y],
                    +datePart[mapFormat.m]-1,
                    +datePart[mapFormat.d] ];
            if (dateStr2Array.length > 3) {
                dateStr2Array.slice(3).forEach(t => datePartFormatted.push(+t));
            }
            // test date validity according to given [format]
            const dateTrial = new Date(Date.UTC.apply(null, datePartFormatted));
            return dateTrial && dateTrial.getFullYear() === datePartFormatted[0] &&
                dateTrial.getMonth() === datePartFormatted[1] &&
                dateTrial.getDate() === datePartFormatted[2]
                    ? dateTrial :
                    null;
        }
    }
 
    logChildValue = (kpi, date, ppto) => {
        // this.setState({
        //     isLoading: false
        // });
        console.log('datos calendario')
        let hour = ''
        this.setState({
            kpi,
            ppto,
            hour,
            calendarDate: true,
        })
        console.log("estoy en calendario con Fecha :", date)
        this.handleOldSales(date)
        const dateHour = new Date()
        if (moment(dateHour).format('YYYY-MM-DD') === this.state.kpi[0].FECHA) {
             const hour = dateHour.getHours()
             this.setState({hour})
        }
    }

    logChildValueOld = (kpiold) => {
        console.log('datos antiguos')
        let hour = ''
        this.setState({
            kpiold,
            hour,
            calendarDate: false,
        })
        console.log('old ' + this.state.kpiold.length)

    }

    logChildValueNew = (kpi, ppto) => {
        console.log('datos nuevos')
        let hour = ''
        this.setState({
            kpi,
            ppto,
            hour,
            calendarDate: false,
        })
        const dateHour = new Date()
        if (moment(dateHour).format('YYYY-MM-DD') === this.state.kpi[0].FECHA) {
             const hour = dateHour.getHours()
             this.setState({hour})
        }
    }

    sendEmail = () => {
        sales.email(this.state.kpi)
    }
    
    fun = (x) => {
        let ne = this.state.kpi.filter(function(val, i) {
            if (val.CODIGO !== 'COMP' && val.CODIGO !== 'NO COMP' && val.CODIGO !== 'FRANQ') {
                return val.TIPO===x;
                } 
            })
        return ne
    }
   
    toggle = () => {
        console.log('pulsado')
        var lab = $("label");
        var swi = $(".custom-switch");
        if (lab.css("color",'green!important')) {
            lab.css("color",'red!important')
            } else {
                lab.css("color",'green!important')
            }
    }

    formatAsPercentage = (num) => {
        return new Intl.NumberFormat('default', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(num / 100);
    }

    test = (event) => {
            // $('table').hide();
            // $("#searchBar").change(function() {
            const { name, value } = event.target;
            // this.setState({ [name]: value });   
            // $("#searchBar").on('input',function() {
              
            $('table').show();
            var selection = value.toUpperCase();
            var dataset = $('#myTable tbody').find('tr');
              // show all rows first
            dataset.show();
              // filter the rows that should be hidden
            dataset.filter(function(index, item) {
                return  !$(item).find('th > h3, th > p').text().toUpperCase().includes(selection);
            }).hide();

            
            // dataset.filter(function(index, item) {
            //     return !$(item).find('th > h3').text().toUpperCase().includes(selection);
            // }).hide();
              
            //   dataset.filter(function(index, item) {
            //     return $(item).find('th h3').text().split(',').indexOf(selection) === -1;
            //   }).hide();
            // });
            
         
    }
   
    updateAlert = () => {

        let {hora, min} = this.state
        let horaNow = moment().format("HH")
        let minNow =  moment().format("mm")

        // console.log('hora: ' + hora)
        // console.log('min: ' + min)
        // console.log('hora actual: ' + horaNow)
        // console.log('min actual' + minNow)

        if (hora = horaNow && minNow > Number(min)+10 ) {
            console.log('retraso en actualización')
            sales.email('retraso en actualización')
            
        } else {
            console.log('onTime')
        }
    }

    render () {

        /*------------ SCROLL APP ------------*/
        window.addEventListener("visibilitychange", function () {
            // console.log("Visibility changed");
            if (document.visibilityState === "visible") {
            //   console.log("APP resumed");
              window.location.reload();
            }
          });
        const { logout } = this.props;
     
        /*------------ FORMAT NUM ------------*/
        const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
       
     
        /*---------HIDE NAV-------*/
        $(function() {
            var header = $(".fixNav");
            $(window).scroll(function() {    
                var scroll = $(window).scrollTop();
            
                if (scroll >= 150) {
                    header.removeClass('fixNav').addClass("delNav");
                } else {
                    header.removeClass("delNav").addClass('fixNav');
                }
            });
            
        });

        return (

        <div className="AppPL">
            <div className='fixNavPpto'>
            <div style={{background: '#c95f2c'}}>
            <button style={{background: '#c95f2c'}} className="logout" onClick={logout}><img src="../Images/18404466911582779196-128.png" alt="log" style={{width: '1rem'}}/></button>
            </div>
            <nav className="NavPLPpto">
                <div className="PLPpto">
                <h1>PANEL DE PRESUPUESTO</h1>
                <h3>Fecha:<p> {this.state.kpi[0].FECHA}</p></h3>
                {/* <h3>Fecha comparativa:<p> {this.state.kpiold[0].FECHA}</p></h3> */}
                </div>
                {/* <Cal parentTextBoxValue={this.state.parentTextBoxValue} calendarDate={this.state.calendarDate} onSubmitChild={this.logChildValue} /> */}
                {/* <div className="cal">
                    <h3>SELECCIONA FECHAS A COMPARAR</h3>
                    <div className="cal2">
                    <CalNew parentTextBoxValue={this.state.parentTextBoxValue} calendarDate={this.state.calendarDate} onSubmitChild={this.logChildValueNew} />
                    <CalOld parentTextBoxValue={this.state.parentTextBoxValue} calendarDate={this.state.calendarDate} date={this.state.kpiold[0].FECHA} onSubmitChild={this.logChildValueOld} />
                    </div>
                </div> */}
            </nav>
            <div className='hourBar'>
                <h1 className='hora'>HORA DE ACTUALIZACIÓN {this.state.hora}:{this.state.min}</h1>
                {this.state.isLoading === false ?
                
                  <div className="loadup" >
                  <LoadingDots color={'white'}/>   
                  </div>          
                :
                <div>
                <IconContext.Provider value={{ className:"upcon"}}>
                <button style={{background: '#c95f2c'}} className="up"onClick={this.handledbComplete}><GrUpdate/></button>
                </IconContext.Provider>
                </div>
                }
            </div>
            </div>
        <header className="pptoHeader">
        <table className="tablePpto" id='myTable'>
            <thead>
                <tr className='trPpto'>
                    <th>Año</th>
                    <th>Ce.coste</th>
                    <th>Acreedor</th>
                    <th>Ce.coste</th>
                    <th>Proyecto</th>
                    <th>Nombre</th>
                    <th>NIF</th>
                    <th>Denominación</th>
                    <th>Demnomin.</th>
                    <th>Enero Presupuesto</th>
                    <th>Enero Comprometido</th>
                    <th>Enero Provisionado</th>
                    <th>Enero Real</th>
                    <th>Febrero Presupuesto</th>
                    <th>Febrero Comprometido</th>
                    <th>Febrero Provisionado</th>
                    <th>Febrero Real</th>
                    <th>Marzo Presupuesto</th>
                    <th>Marzo Comprometido</th>
                    <th>Marzo Provisionado</th>
                    <th>Marzo Real</th>
                    <th>Abril Presupuesto</th>
                    <th>Abril Comprometido</th>
                    <th>Abril Provisionado</th>
                    <th>Abril Real</th>
                    <th>Mayo Presupuesto</th>
                    <th>Mayo Comprometido</th>
                    <th>Mayo Provisionado</th>
                    <th>Mayo Real</th>
                    <th>Junio Presupuesto</th>
                    <th>Junio Comprometido</th>
                    <th>Junio Provisionado</th>
                    <th>Junio Real</th>
                    <th>Julio Presupuesto</th>
                    <th>Julio Comprometido</th>
                    <th>Julio Provisionado</th>
                    <th>Julio Real</th>
                    <th>Agosto Presupuesto</th>
                    <th>Agosto Comprometido</th>
                    <th>Agosto Provisionado</th>
                    <th>Agosto Real</th>
                    <th>Septiembre Presupuesto</th>
                    <th>Septiembre Comprometido</th>
                    <th>Septiembre Provisionado</th>
                    <th>Septiembre Real</th>
                    <th>Octubre Presupuesto</th>
                    <th>Octubre Comprometido</th>
                    <th>Octubre Provisionado</th>
                    <th>Octubre Real</th>
                    <th>Noviembre Presupuesto</th>
                    <th>Noviembre Comprometido</th>
                    <th>Noviembre Provisionado</th>
                    <th>Noviembre Real</th>
                    <th>Diciembre Presupuesto</th>
                    <th>Diciembre Comprometido</th>
                    <th>Diciembre Provisionado</th>
                    <th>Diciembre Real</th>
                    <th>Total Presupuesto</th>
                    <th>Total Comprometido</th>
                    <th>Total Provisionado</th>
                    <th>Total Real</th>
                </tr>
            </thead>
            <tbody>
                {this.state.sap.map ((sap, idx)  => { 
                return <> 

                    <tr className='trBodyPpto'>
                        <th><h4>{sap.GJAHR}</h4></th>
                        <th><h4>{sap.KOSTL_ORIGEN}</h4></th>
                        <th><h4>{sap.LIFNR}</h4></th>
                        <th><h4>{sap.KOSTL_DESTINO}</h4></th>
                        <th className="zndx"><h4>{sap.PROYECTO}</h4></th>
                        <th><h4>{sap.NAME1}</h4></th>
                        <th><h4>{sap.STCD1}</h4></th>
                        <th><h4>{sap.KTEXT_OR}</h4></th>
                        <th><h4>{sap.KTEXT_DE}</h4></th>
                        <td><h4>{sap.ENERO_PRESUP}</h4></td>
                        <td><h4>{sap.ENERO_COMP}</h4></td>
                        <td><h4>{sap.ENERO_PROVI}</h4></td>
                        <td><h4>{sap.ENERO_REAL}</h4></td>
                        <td><h4>{sap.FEBRERO_PRESUP}</h4></td>
                        <td><h4>{sap.FEBRERO_COMP}</h4></td>
                        <td><h4>{sap.FEBRERO_PROVI}</h4></td>
                        <td><h4>{sap.FEBRERO_REAL}</h4></td>
                        <td><h4>{sap.MARZO_PRESUP}</h4></td>
                        <td><h4>{sap.MARZO_COMP}</h4></td>
                        <td><h4>{sap.MARZO_PROVI}</h4></td>
                        <td><h4>{sap.MARZO_REAL}</h4></td>
                        <td><h4>{sap.ABRIL_PRESUP}</h4></td>
                        <td><h4>{sap.ABRIL_COMP}</h4></td>
                        <td><h4>{sap.ABRIL_PROVI}</h4></td>
                        <td><h4>{sap.ABRIL_REAL}</h4></td>
                        <td><h4>{sap.MAYO_PRESUP}</h4></td>
                        <td><h4>{sap.MAYO_COMP}</h4></td>
                        <td><h4>{sap.MAYO_PROVI}</h4></td>
                        <td><h4>{sap.MAYO_REAL}</h4></td>
                        <td><h4>{sap.JUNIO_PRESUP}</h4></td>
                        <td><h4>{sap.JUNIO_COMP}</h4></td>
                        <td><h4>{sap.JUNIO_PROVI}</h4></td>
                        <td><h4>{sap.JUNIO_REAL}</h4></td>
                        <td><h4>{sap.JULIO_PRESUP}</h4></td>
                        <td><h4>{sap.JULIO_COMP}</h4></td>
                        <td><h4>{sap.JULIO_PROVI}</h4></td>
                        <td><h4>{sap.JULIO_REAL}</h4></td>
                        <td><h4>{sap.AGOSTO_PRESUP}</h4></td>
                        <td><h4>{sap.AGOSTO_COMP}</h4></td>
                        <td><h4>{sap.AGOSTO_PROVI}</h4></td>
                        <td><h4>{sap.AGOSTO_REAL}</h4></td>
                        <td><h4>{sap.SEPTIEMBRE_PRESUP}</h4></td>
                        <td><h4>{sap.SEPTIEMBRE_COMP}</h4></td>
                        <td><h4>{sap.SEPTIEMBRE_PROVI}</h4></td>
                        <td><h4>{sap.SEPTIEMBRE_REAL}</h4></td>
                        <td><h4>{sap.OCTUBRE_PRESUP}</h4></td>
                        <td><h4>{sap.OCTUBRE_COMP}</h4></td>
                        <td><h4>{sap.OCTUBRE_PROVI}</h4></td>
                        <td><h4>{sap.OCTUBRE_REAL}</h4></td>
                        <td><h4>{sap.NOVIEMBRE_PRESUP}</h4></td>
                        <td><h4>{sap.NOVIEMBRE_COMP}</h4></td>
                        <td><h4>{sap.NOVIEMBRE_PROVI}</h4></td>
                        <td><h4>{sap.NOVIEMBRE_REAL}</h4></td>
                        <td><h4>{sap.DICIEMBRE_PRESUP}</h4></td>
                        <td><h4>{sap.DICIEMBRE_COMP}</h4></td>
                        <td><h4>{sap.DICIEMBRE_PROVI}</h4></td>
                        <td><h4>{sap.DICIEMBRE_REAL}</h4></td>
                        <td><h4>{sap.TOTAL_PRESUPUESTO}</h4></td>
                        <td><h4>{sap.TOTAL_COMPROMETIDO}</h4></td>
                        <td><h4>{sap.TOTAL_PROVISIONADO}</h4></td>
                        <td><h4>{sap.TOTAL_REAL}</h4></td>
                    </tr>
               </>
                } )}
            </tbody>
            </table>
 
            {this.state.isLoading === false ?
             <LoadingDots/>               
            :
            <div>

                {/* <Button variant="info" className='butn' onClick={() => this.handleSales()}>READ FROM CMZ4</Button>
                <Button variant="primary" className='butn' onClick={() => this.handledb()}>READ FROM MONGO</Button>
                <Button variant="secondary" className='butn' onClick={() => this.handleOldSales()}>READ OLD MONGO</Button>*/}
                {/* <Button variant="secondary" className='butn' onClick={() => this.handleSalesYear()}>Update DB</Button> */}
                {/* <Button variant="secondary" className='butn' onClick={() => this.sendEmail()}>SendEmail</Button>
                <Button variant="secondary" className='butn' onClick={() => this.handlesftpComplete()}>ReadSFTP</Button>  */}
                {/* <Button variant="secondary" className='butn' onClick={() => this.handleSapComplete()}>ReadSAP</Button>  */}

           
           

            </div>
            }
        </header>
        </div>
        )
    }
}

export default withAuth(Dev);